body {
  margin: 0;
  padding: 0 !important;
  font-family: sans-serif;
}

ul.navi {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: flex-end;
}

ul.navi li {
  padding: 10px;
}
